import React, { useState } from "react";
import { FooterSection } from "../Components/LauncherPageComponent/launcherPageComponent";
import CarouselBoothstrapComponent from "../Components/LauncherPageComponent/carouselBoothstrapComponent";
import { imagesLinks } from "../Constants/links";
import "../Components/styles/redeemPage.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

function RedeemPage() {
  const [recipientEmail, setRecipientEmail] = useState("")
  const { id, email, token } = useParams();

  const handleRedeemClick = async () => {
    try {
      const response = await axios.post('http://3.25.86.125:3000/api/redeem/code', {
        referrerDeviceId: id,
        referrerEmail: email,
        recipientEmail,
        redeemCode: token
      });
      console.log('Redemption successful', response.data);
      toast.success("Redemption successful")
      setRecipientEmail("")
    } catch (error) {
      console.error("Internal server error", error);
      toast.error('Redemption failed')
    }
  };

  return (
    <>
      <div>
      <Toaster/>
        <div className="redeem-1-month-container">
          <div className="redeem-trial">
            <div className="redeem-minimis-logo">
              <img src={imagesLinks.minimisWhiteLogo} alt="" />
            </div>
            <div className="redeem-text">
              <p>Minimise Distractions. Maximise Life.</p>
            </div>
            <div className="redeem-line"></div>
            <div className="redeem-trial-text">
              <p>1 month free trial*</p>
            </div>
          </div>
          <div className="redeem-code-container">
            <div className="redeem-code-text">
              <div className="redeem-code-heading">
                <p>
                  You have been invited to experience Minimis Phone free for 30
                  days
                </p>
              </div>
              <div className="redeem-code-content">
                <p>
                  Minimis Phone brings your phone back to basics. You control
                  your phone. Not the other way around.
                </p>
              </div>
              <div className="redeem-code-input-container">
                <div className="redeem-code-input">
                  <input value={recipientEmail}
                    onChange={(e) => setRecipientEmail(e.target.value)}
                    type="text"
                    placeholder="Your email..."
                  />
                </div>
                <button onClick={handleRedeemClick}>Redeem</button>
              </div>
            </div>
            <div className="redeem-code-image">
              <img src={imagesLinks.iosRewviewimage} alt="" />
            </div>
          </div>
        </div>
        <div className="how-work-conatiner">
          <div className="how-work">
            <p>How it works?</p>
          </div>
          <div className="how-work-method">
            <div className="how-work-heading">
              <div className="how-work-icon">
                <img src={imagesLinks.downloadIcon} alt="" />
              </div>
              <div className="how-work-heading-content">
                <p>Download Minimis Phone</p>
              </div>
            </div>
            <div className="how-work-text-content">
              <div className="how-work-text">
                <p style={{ minHeight: "24px" }}></p>
              </div>
            </div>
          </div>
          <div className="how-work-method">
            <div className="how-work-heading">
              <div className="how-work-icon">
                <img src={imagesLinks.reuseIcon} alt="" />
              </div>
              <div className="how-work-heading-content">
                <p>Setup your minimalist home screen</p>
              </div>
            </div>
            <div className="how-work-text-content">
              <div className="how-work-text">
                <p>
                  In just 3 minutes, Minimis Phone detects your most addictive
                  apps. All you need to do is choose which apps are essential.
                </p>
              </div>
            </div>
          </div>
          <div className="how-work-method">
            <div className="how-work-heading">
              <div className="how-work-icon">
                <img src={imagesLinks.settingIcon} alt="" />
              </div>
              <div className="how-work-heading-content">
                <p>Customise your digital experience</p>
              </div>
            </div>
            <div className="how-work-text-content">
              <div className="how-work-text">
                <p>
                  After setup, customize your home screen and organize your
                  apps; renaming, reordering, and prioritizing however you wish.
                </p>
              </div>
            </div>
          </div>
          <div className="how-work-method">
            <div className="how-work-heading">
              <div className="how-work-icon">
                <img src={imagesLinks.smileEmoijIcon} alt="" />
              </div>
              <div className="how-work-heading-content">
                <p>Enjoy a more balanced life</p>
              </div>
            </div>
            {/* <div className="how-work-text-content">
                <div className="how-work-text">
                    <p></p>
                </div>
                </div> */}
          </div>
        </div>
        <div className="android-download-review">
          <p className="android-download-review-heading">
            Here’s what our customers are saying
          </p>
          <CarouselBoothstrapComponent />
        </div>
        <div className="footer_section">
          <FooterSection />
        </div>
      </div>
    </>
  );
}

export default RedeemPage;
