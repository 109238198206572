import React, { Fragment, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { imagesLinks } from "../Constants/links";
import "../Components/styles/CheckoutForm.css";
import "../Components/styles/launcherPageComponent.css";
import CheckoutForm from "../Components/checkoutComponent/checkoutForm";
import { FooterSection } from "../Components/LauncherPageComponent/launcherPageComponent";
import SDK from "../config";

const stripePromise = loadStripe(SDK.STRIPE_KEY);

const CheckOutPage = () => {
  const [cardBoxPosition, setCardBoxPosition] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const updateScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 830);
    };
    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  const location = useLocation();
  const isReservationRoute = location.pathname === "/payment-checkout";

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 830);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 830);
    };

    // Initial check when the component mounts
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <Fragment>
        <div style={{ overflow: "hidden" }}>
          <div className="reservation_page">
            <div className="text_part">
              <div className="reservation_logo">
                <div>
                  <img src={imagesLinks.logo} className="navLogo" alt="" />
                </div>
              </div>

              <CheckoutForm setCardBoxPosition={setCardBoxPosition} />
            </div>

            <div
              className={`cards_part ${
                isReservationRoute ? "reservation-checkout" : ""
              }`}
            >
              <div
                className={`img_text_box ${
                  isSmallScreen && cardBoxPosition ? "move-up-small" : ""
                }`}
              >
                <div className="card-img-text-box">
                  <div className="card-img-text-box-background"></div>
                  <div className="image_iphone"></div>
                </div>
                <div
                  className={`reservation_crad_box ${
                    isReservationRoute ? "reservation_checkout_box" : ""
                  }`}
                >
                  <div className="card1">
                    <div className="card_icon">
                      <img src={imagesLinks.c1} alt="" />
                    </div>
                    <div className="card_text">
                      <p className="card_p1">
                        Lock in a massive lifetime discount
                      </p>
                      <p className="card_p2">
                        Exclusive pricing available for a limited time only!
                      </p>
                    </div>
                  </div>
                  <div className="card1 card-bottom">
                    <div className="card_icon">
                      <img src={imagesLinks.c3} alt="" />
                    </div>
                    <div className="card_text">
                      <p className="card_p1">Payment secured by Stripe</p>
                      <p className="card_p2">
                        The same payment processor Apple, Amazon, Etsy, Walmart
                        & Target use. No credit card details seen or stored by
                        us!
                      </p>
                    </div>
                  </div>
                  {isMobileView && (
                    <div className="footer_section">
                      <FooterSection />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {!isMobileView && (
            <div className="footer_section">
              <FooterSection />
            </div>
          )}
        </div>
      </Fragment>
    </Elements>
  );
};

export default CheckOutPage;
