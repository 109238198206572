import { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import ScrollToTop from "./Components/scrollToTop/scrollToTop";
import AppRoutes from "./routes/routes";

const App = () => {
  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
  }, []);
  
  return (
    <div className="dark">
      <ScrollToTop />
      <AppRoutes />
    </div>
  );
};

export default App;
