import Carousel from "react-bootstrap/Carousel";
import { imagesLinks } from "../../Constants/links";
import React, { Fragment, useEffect, useState } from "react";
import { reviewsData } from "../../Constants/reviewData";

function CarouselBoothstrapComponent() {
  const [isTabletView, setIsTabletView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsTabletView(window.innerWidth <= 849);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Fragment>
      {isTabletView ? (
        <Carousel className="main-carousel-container">
          {reviewsData &&
            reviewsData.map((review, index) => (
              <Carousel.Item key={index} style={{ width: "100vw" }}>
                <div className="Carousel-main-container">
                  <div className="carousel-slder-container">
                    <div className="carousel-container">
                      <div className="android-download-users-review">
                        <div className="android-download-app-rating">
                          <div className="android-play-store-icon">
                            <img
                              src={imagesLinks.playStoreIcon}
                              alt="Play Store Icon"
                            />
                          </div>
                          <div className="android-download-rating">
                            <div>
                              <img
                                src={review.ratingImage}
                                alt="Rating"
                                style={{ width: "96px", height: "16px" }}
                              />
                            </div>
                            <div className="android-download-rating-text">
                              <p>-{review.name}</p>
                            </div>
                          </div>
                        </div>
                        <div className="android-downlod-rating-review-center"></div>
                        <center>
                          <div className="android-download-app-review">
                            {review.review.map((textObj, idx) => (
                              <React.Fragment key={idx}>
                                {idx > 0 && " "}
                                <span
                                  className={
                                    textObj.type === "light"
                                      ? "android-download-app-review-light-text"
                                      : "android-download-app-review-bold-text"
                                  }
                                >
                                  {textObj.text}
                                </span>
                              </React.Fragment>
                            ))}
                          </div>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            ))}
        </Carousel>
      ) : (
        <Carousel className="Carousel-main-container">
          {reviewsData
            .reduce((rows, review, index) => {
              if (index % 2 === 0) {
                rows.push([]);
              }
              rows[rows.length - 1].push(review);
              return rows;
            }, [])
            .map((row, rowIndex) => (
              <Carousel.Item key={rowIndex} style={{ width: "100%" }}>
                <div className="d-flex justify-content-center carousel-slder-container ">
                  {row.map((review, index) => (
                    <div
                      className="review-item"
                      style={{ width: "50%" }}
                      key={index}
                    >
                      <div className="android-download-app-rating">
                        <div className="android-play-store-icon">
                          <img
                            src={imagesLinks.playStoreIcon}
                            alt="Play Store Icon"
                          />
                        </div>
                        <div className="android-download-rating">
                          <div>
                            <img
                              src={review.ratingImage}
                              alt="Rating"
                              style={{ width: "96px", height: "16px" }}
                            />
                          </div>
                          <div className="android-download-rating-text">
                            <p>-{review.name}</p>
                          </div>
                        </div>
                      </div>
                      <div className="android-downlod-rating-review-center"></div>
                      <div className="android-download-app-review">
                        {review.review.map((textObj, idx) => (
                          <React.Fragment key={idx}>
                            {idx > 0 && " "}
                            <span
                              className={
                                textObj.type === "light"
                                  ? "android-download-app-review-light-text"
                                  : "android-download-app-review-bold-text"
                              }
                            >
                              {textObj.text}
                            </span>
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </Carousel.Item>
            ))}
        </Carousel>
      )}
    </Fragment>
  );
}

export default CarouselBoothstrapComponent;
