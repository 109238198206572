import LauncherPage from "../pages/launcherPage";
import ReservationPage from "../pages/reservationPage";
import PrivacyPolicies from "../pages/privacyPolicies";
import CheckOutPage from "../pages/checkOutPage";
import ConfirmationPageComponent from "../pages/confirmationPage";
import TermsOfServicesPage from "../pages/termsOfServicesPage";
import { Route, Navigate, Routes } from "react-router-dom";
import RedeemPage from "../pages/redeemPage";

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<LauncherPage />} />
            <Route path="/reservation" element={<ReservationPage />} />
            <Route path="/launcher-privacy-policy" element={<PrivacyPolicies />} />
            <Route path="/terms-of-service" element={<TermsOfServicesPage />} />
            <Route path="/payment-checkout" element={<CheckOutPage />} />
            <Route path="/confirmation" element={<ConfirmationPageComponent />} />
            <Route path="/*" element={<Navigate to="/" replace />} />
            <Route path="/redeem/:id/:email/:token" element={<RedeemPage />} />
        </Routes>
    )
}

export default AppRoutes
