import linkedinIcon from "../assests/launcher_page_img/linkedin-icon.png";
import facebookIcon from "../assests/launcher_page_img/facebook-icon.png";
import instagramIcon from "../assests/launcher_page_img/instagram-icon.png";
import confirmationImage from "../assests/launcher_page_img/iPhone-14-Pro-Mockup 1.png";
import c1 from "../assests/images/massive-lifetime-discount-icon.svg";
import c2 from "../assests/images/early-development-icon.svg";
import c3 from "../assests/images/payment-secured-icon.svg";
import logo from "../assests/images/MINIMIS (1).svg";
import iosRewviewimage from "../assests/launcher_page_img/IOS-review-image.png";
import androidMinimisLauncher from "../assests/launcher_page_img/android-minimis-launcher-image.png";
import visaIcon from "../assests/images/vis.png";
import mastercardIcon from "../assests/images/deb.png";
import amexIcon from "../assests/images/Amex.png";
import discoverIcon from "../assests/images/dis.png";
import defaultIcon from "../assests/images/defaultim.png";
import playStoreIcon from "../assests/launcher_page_img/play-store-icon.png";
import ratingStarImage from "../assests/launcher_page_img/rating-star-image.png";
import group1img1 from "../assests/launcher_page_img/1st group 1.png";
import group1img2 from "../assests/launcher_page_img/1st group 2.png";
import group1img3 from "../assests/launcher_page_img/1st group 3.png";
import group1img4 from "../assests/launcher_page_img/1st group 4.png";
import group2img1 from "../assests/launcher_page_img/2nd group 1.png";
import group2img2 from "../assests/launcher_page_img/2nd group 2.png";
import group2img3 from "../assests/launcher_page_img/2nd group 3.png";
import group2img4 from "../assests/launcher_page_img/2nd group 4.png";
import group3img1 from "../assests/launcher_page_img/3rd group 1.png";
import group3img2 from "../assests/launcher_page_img/3rd group 2.png";
import group3img3 from "../assests/launcher_page_img/3rd group 3.png";
import group3img4 from "../assests/launcher_page_img/3rd group 4.png";
import logo_light from "../assests/images/logo_light.svg";
import downloadIcon from "../assests/launcher_page_img/download-icon.png";
import reuseIcon from "../assests/launcher_page_img/reuse-icon.png";
import settingIcon from "../assests/launcher_page_img/setting-icon.png";
import smileEmoijIcon from "../assests/launcher_page_img/review-emoij-icon.png";
import minimisWhiteLogo from "../assests/images/MINIMIS-white.svg";
import minimisTeam from "../assests/launcher_page_img/MINIMIS-Team-Photo 1.png";

export const socialLinks = {
  instagram: "https://www.instagram.com/minimistech/",
  facebook: "https://www.facebook.com/minimistech",
  linkedin: "https://www.linkedin.com/company/minimis-technologies",
  iOSPageLink: "https://app.minimis.life/"
};

export const imagesLinks = {
  group1img1,
  group1img2,
  group1img3,
  group1img4,
  group2img1,
  group2img2,
  group2img3,
  group2img4,
  group3img1,
  group3img2,
  group3img3,
  group3img4,
  linkedinIcon,
  facebookIcon,
  instagramIcon,
  confirmationImage,
  visaIcon,
  mastercardIcon,
  amexIcon,
  discoverIcon,
  defaultIcon,
  playStoreIcon,
  ratingStarImage,
  c1,
  c2,
  c3,
  logo,
  iosRewviewimage,
  androidMinimisLauncher,
  logo_light,
  downloadIcon,
  reuseIcon,
  settingIcon,
  smileEmoijIcon,
  minimisWhiteLogo,
  minimisTeam
}