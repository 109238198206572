import { imagesLinks } from "../Constants/links";

export const reviewsData = [
    {
      name: "Constantijn",
      ratingImage: imagesLinks.ratingStarImage,
      review: [
        { text: "Minimis is the only minimalist launcher that has transformed my phone habits.", type: "light" },
        { text: "For the first time, I feel like I control my phone, not the other way around.", type: "bold" },
        { text: "The added friction layers between you and those 'bad' dopamine-stealing apps work brilliantly. You even feel guilty thinking about opening one!", type: "light" },
        { text: "Minimis has changed my life.", type: "bold" },
        { text: "My focus strengthens daily, my baseline anxiety has dropped, and I get more done. My brain feels better overall, like in the 'good old days' of the 90s, I feel calm. :)", type: "light" },
      ],
    },
    {
      name: "Stephen",
      ratingImage: imagesLinks.ratingStarImage,
      review: [
        { text: "This has been one of", type: "light" },
        { text: "the most amazing game changers in keeping me accountable to being more involved with my kiddos", type: "bold" },
        { text: "and not scrolling on social media or YouTube. When I do need a YouTube video, I have to intentionally go to my iPad or computer. It is the best on android (even though I was an iPhone user) because the experience is immersive.", type: "light" },
      ],
    },
    {
      name: "Bill",
      ratingImage: imagesLinks.ratingStarImage,
      review: [
        { text: "I highly recommend this app. The UI is great and it's definitely reduced the amount of time that I spend distracted.Love that upon opening the app drawer it opens a list of 'essential apps' that you've selected,", type: "light" },
        { text: " thus saving you from scrolling past dozens of random apps.", type: "bold" },
        { text: "I'm looking forward to the continued development of the app and future features.", type: "light" },
      ],
    },
    {
      name: "Elisabeth",
      ratingImage: imagesLinks.ratingStarImage,
      review: [
        { text: "Minimis Launcher has helped me tamp down phone distraction by making the apps I actually need easily accessible, and the apps I want to minimize not-so-easy to access. Perfect!", type: "light" },
        { text: "The home screen is simple and beautiful. I love it!", type: "bold" },
        { text: "I highly recommend this app for anyone who desires to be thoughtful and intentional about how they use technology.", type: "light" },
      ],
    },
    {
      name: "Meghan",
      ratingImage: imagesLinks.ratingStarImage,
      review: [
        { text: "I cannot express, over and over again how much I appreciate this app.", type: "light" },
        { text: "It has genuinely helped my scrolling, and I'm making art again :)", type: "bold" },
        { text: "Thank you!", type: "light" },
      ],
    },
  ];