import React from 'react';
import '../Components/styles/privacyPolicies.css'

const PrivacyPolicies = () => {
    return (
        <div className='col-10 offset-1'>
            <h5 className="heading">Privacy Policy</h5>
            <p className="paragraph ms-2">Minimis Technologies Proprietary Limited</p>
            <ol className="orderList1">
                <li className="orderHeading"><h5>We respect your privacy</h5>
                    <ol type='a' className="orderList2">
                        <li className="orderHeading2"><p className="paragraph1">Minimis Technologies Proprietary Limited respects your right to privacy and is committed to
                            safeguarding the privacy of our customers and software application users. We adhere to
                            the National Privacy Principles established by the Privacy Act 1988 (Cth). This policy sets
                            out how we collect and treat your personal information.</p></li>
                        <li className="orderHeading2"><p className="paragraph1"><strong>Personal information</strong> means information we hold which is identifiable as being about you.</p></li>
                    </ol>
                </li>
                <li className="orderHeading"><h5>Collection of personal information</h5>
                    <ol type='a' className="orderList2">
                        <li className="orderHeading2"><p className="paragraph1">Minimis Technologies Proprietary Limited will, from time to time, receive and store personal
                            information you enter onto our software application Minimis Launcher, provide to us directly
                            or give to us in other forms.</p></li>
                        <li className="orderHeading2"><p className="paragraph1">You may provide basic information such as your name, phone number, address and email
                            address to enable us to send information, provide updates and process your product or
                            service order. We may collect additional information at other times, including but not limited
                            to, when you provide feedback, when you provide information about your personal or
                            business affairs, change your content or email preference, respond to surveys and/or
                            promotions, provide financial or credit card information, or communicate with our customer
                            support.</p></li>
                        <li className="orderHeading2"><p className="paragraph1">Additionally, we may also collect any other information you provide while interacting with
                            us.</p></li>
                    </ol>
                </li>
                <li className="orderHeading"><h5>How we collect your personal information</h5>
                    <p className="paragraph2">Minimis Technologies Proprietary Limited collects personal information from you in a variety of
                        ways, including when you interact with us electronically or in person, when you access our
                        software application and when we provide our services to you. We may receive personal
                        information from third parties. If we do, we will protect it as set out in this Privacy Policy.</p>
                </li>
                <li className="orderHeading"><h5>Use of your personal information</h5>
                    <ol type='a' className="orderList2">
                        <li className="orderHeading2"><p className="paragraph1">Minimis Technologies Proprietary Limited may use personal information collected from you
                            to provide you with information, updates and our services. We may also make you aware of
                            new and additional products, services and opportunities available to you. We may use your
                            personal information to improve our products and services and better understand your
                            needs.</p></li>
                        <li className="orderHeading2"><p className="paragraph1">The Application may make third party social media features available to its users. We
                            cannot ensure the security of any information you choose to make public in a social media feature. Also, we cannot ensure that parties who have access to such publicly available
                            information will respect your privacy.
                        </p></li>
                        <li className="orderHeading2"><p className="paragraph1">Minimis Technologies Proprietary Limited may contact you by a variety of measures
                            including, but not limited to telephone, email, sms or mail.</p></li>
                    </ol>
                </li>
                <li className="orderHeading"><h5>Disclosure of your personal information</h5>
                    <ol type='a' className="orderList2">
                        <li className="orderHeading2"><p className="paragraph1">We may share your personal information and app usage data (such as whether you
                            have launched the app, whether you're actively using the app, and whether you have
                            subscribed) to our agents, contractors and service providers who are engaged to
                            perform functions on our behalf (such as processing of payments, provision of data
                            storage, hosting of our website, marketing of our products and services, conducting
                            audits, and performing web analytics). These third-party contractors and service
                            providers:
                            <ol type="i">
                                <li className='ps-4'>shall be permitted to obtain only the personal information they need to provide the
                                    service, and</li>
                                <li className='ps-4'>must protect personal information to the same extent as Minimis Technologies,
                                    including not using it for any improper purpose.</li>
                            </ol></p></li>
                        <li className="orderHeading2"><p className="paragraph1">We may from time to time need to disclose personal information to comply with a legal
                            requirement, such as a law, regulation, court order, subpoena, warrant, in the course of a
                            legal proceeding or in response to a law enforcement agency request.
                        </p></li>
                        <li className="orderHeading2"><p className="paragraph1">We may also use your personal information to protect the copyright, trademarks, legal
                            rights, property or safety of Minimis Technologies Proprietary Limited, its application,
                            website and customers or third parties.</p></li>
                        <li className="orderHeading2"><p className="paragraph1">Information that we collect may from time to time be stored, processed in or transferred
                            between parties located in countries outside of Australia. These may include, but are not
                            limited to USA and Pakistan.</p></li>
                        <li className="orderHeading2"><p className="paragraph1">If there is a change of control in our business or a sale or transfer of business assets, we
                            reserve the right to transfer to the extent permissible at law our user databases, together
                            with any personal information and non-personal information contained in those databases.
                            This information may be disclosed to a potential purchaser under an agreement to maintain
                            confidentiality. We would seek to only disclose information in good faith and where required
                            by any of the above circumstances.</p></li>
                        <li className="orderHeading2"><p className="paragraph1">By providing us with personal information, you consent to the terms of this Privacy Policy
                            and the types of disclosure covered by this Policy. Where we disclose your personal
                            information to third parties, we will request that the third party follow this Policy regarding
                            handling your personal information.</p></li>
                    </ol>
                </li>
                <li className="orderHeading"><h5>Security of your personal information</h5>
                    <ol type='a' className="orderList2">
                        <li className="orderHeading2"><p className="paragraph1">Minimis Technologies Proprietary Limited is committed to ensuring that the information you
                            provide to us is secure. In order to prevent unauthorised access or disclosure, we have put
                            in place suitable physical, electronic and managerial procedures to safeguard and secure
                            information and protect it from misuse, interference, loss and unauthorised access,
                            modification and disclosure.</p></li>
                        <li className="orderHeading2"><p className="paragraph1">The transmission and exchange of information is carried out at your own risk. We cannot
                            guarantee the security of any information that you transmit to us, or receive from us. Although we take measures to safeguard against unauthorised disclosures of information,
                            we cannot assure you that personal information that we collect will not be disclosed in a
                            manner that is inconsistent with this Privacy Policy.
                        </p></li>
                    </ol>
                </li>
                <li className='orderHeading'><h5>Access to your personal information</h5>
                    <ol type='a' className="orderList2">
                        <li className="orderHeading2"><p className="paragraph1">You may request details of personal information that we hold about you in accordance with
                            the provisions of the Privacy Act 1988(Cth). A small administrative fee may be payable for
                            the provision of information. If you would like a copy of the information which we hold about
                            you or believe that any information we hold on you is inaccurate, out of date, incomplete,
                            irrelevant or misleading, please email us at joseph@minimis.life.</p></li>
                        <li className="orderHeading2"><p className="paragraph1">We reserve the right to refuse to provide you with information that we hold about you, in
                            certain circumstances set out in the Privacy Act.
                        </p></li>
                    </ol>
                </li>
                <li className="orderHeading"><h5>Complaints about privacy</h5>
                    <p className="paragraph2">If you have any complaints about our privacy practices, please feel free to send in details of your
                        complaints to Unit 101 55A-57 Stanley St, Burwood, New South Wales, 2134. We take
                        complaints very seriously and will respond shortly after receiving written notice of your complaint.</p>
                </li>
                <li className='orderHeading'><h5>Opt-out right</h5>
                    <p className="paragraph2">You can stop all collection of information by the Application easily by uninstalling the Application.
                        You may use the standard uninstall processes as may be available as part of your mobile device
                        or via the mobile application marketplace or network. You can also request to opt-out via email, at
                        joseph@minimis.life.</p>
                </li>
                <li className="orderHeading"><h5>Changes to Privacy Policy</h5>
                    <p className="paragraph2">Please be aware that we may change this Privacy Policy in the future. We may modify this Policy
                        at any time, in our sole discretion and all modifications will be effective immediately upon our
                        posting of the modifications on our website or notice board. Please check back from time to time
                        to review our Privacy Policy.</p>
                </li>
                <li className="orderHeading"><h5>Software Application</h5>
                    <ol className='ps-5 custom-counter'>
                        <li className="orderHeading">
                            <h5>11.1  <span className='ms-4'>When you use our Application</span></h5>
                            <p className="paragraph3">When you come to our application we may collect certain information such as mobile
                                unique device ID, the IP address of your mobile device, mobile operating system, the type
                                of mobile internet browsers you use, and information about the way you use the
                                Application. This information is used in an aggregated manner to analyse how people use
                                our site, such that we can improve our service.</p>
                        </li>
                        <li className="orderHeading">
                            <h5>11.2  <span className='ms-4'>Cookies</span></h5>
                            <p className="paragraph3">We may from time to time use cookies on our software application. Cookies are very small
                                files which a website uses to identify you when you come back to the application and to
                                store details about your use of the application. Cookies are not malicious programs that
                                access or damage your computer, tablet or smartphone. Most devices automatically accept
                                cookies but you can choose to reject cookies by changing your devise settings. However,
                                this may prevent you from taking full advantage of our application</p>
                        </li>
                        <li className="orderHeading">
                            <h5>11.3  <span className='ms-4'>Automatic collection</span></h5>
                            <p className="paragraph3">The software Application may collect certain information automatically, including, but not
                                limited to, the type of mobile device you use, your mobile devices unique device ID, the IP
                                address of your mobile device, your mobile operating system, the type of mobile Internet
                                browsers you use, and information about the way you use the Application.</p>
                        </li>
                        <li className="orderHeading">
                            <h5>11.4  <span className='ms-4'>Third parties</span></h5>
                            <p className="paragraph3">Our software application may from time to time have links to other applications or websites
                                not owned or controlled by us. These links are meant for your convenience only. Links to
                                third party applications and websites do not constitute sponsorship or endorsement or
                                approval of these third parties. Please be aware that Minimis Technologies Proprietary
                                Limited is not responsible for the privacy practises of other such applications or websites.
                                We encourage our users to be aware, when they leave our application or website, to read
                                the privacy statements of each and every application or website that collects personal
                                identifiable information.</p>
                        </li>
                        <li className="orderHeading">
                            <h5>11.5  <span className='ms-4'>Geo-location</span></h5>
                            <p className="paragraph3">When you visit the mobile application, we may use GPS technology (or other similar
                                technology) to determine your current location in order to determine the city you are
                                located within and display a location map with relevant advertisements. We will not share
                                your current location with other users or partners.</p>
                        </li>
                    </ol>

                </li>
            </ol>
        </div>
    );
};

export default PrivacyPolicies;
